<style scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}

.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 80px;
  text-align: right;
}
.price {
  width: 100px;
  text-align: right;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";
import categoryUploadComponent from "@/components/categoryUploadComponent";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ใบจอง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ChangeDate,
    categoryUploadComponent,
  },
  data() {
    return {
      // tableData: tableData,

      title: "ใบจอง",
      optionYear: [
        { id: "2022" },
        { id: "2021" },
        { id: "2020" },
        { id: "2019" },
      ],
      prospectOption: [],
      cusSrcOption: [],
      eventOption: [],
      croOption:[],
      items: [
        {
          text: "ฝ่ายขาย",
        },
        {
          text: "ใบจอง",
          href: "/vehicle-booking",
        },
        {
          text: "แก้ไขใบจอง",
          active: true,
        },
      ],
      fieldProds: [
        {
          key: "prodCode",
          label: "รหัสสินค้า",
        },
        {
          key: "nameTh",
          label: "ชื่อสินค้า",
        },
        {
          key: "nameEn",
          label: "ชื่อสินค้า (อังกฤษ)",
        },
        {
          key: "vehicleBrandNameEn",
          label: "ยี่ห้อ",
        },
        {
          key: "price",
          label: "ราคา",
        },
      ],
      fieldVm: [
        {
          key: "prodCode",
          label: "รหัสสินค้า",
        },
        {
          key: "productNameTh",
          label: "ชื่อสินค้า",
        },
        {
          key: "productNameEn",
          label: "ชื่อสินค้า (อังกฤษ)",
        },
        {
          key: "modelNameTh",
          label: "โมเดล",
        },
        {
          key: "price",
          label: "ราคา",
        },
      ],
      searchProdcode: "",
      searchNameProd: "",
      brand: "",
      bookingDcPrice: 0,
      // branchId:'',
      bookingDate: "",
      bookingDetails: [],
      part: "",
      dcPerAccy: 0,
      amountAccy: 1,
      nameState: null,
      typebookingDetails: "ACCY",
      bookingVatPer: 0.0,
      bookingDcPer: 0,
      bookingNetPrice: 0,
      bookingVatPrice: 0,
      bookingGrandTotalPrice: 0,
      resalert: "",

      branchIdSearch: "",
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,
      disabledWsc: "",

      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      interiorCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      customerG: "",

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณาตรวจสอบข้อมูลอีกครั้ง",

      userBranch: "",
      rowMasterBrands: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      branch: "",
      rowProducts: [],
      rowsCustomers: [],
      rowsFin: [],
      rowsAct: [],
      rowsCampiagn: [],
      rowsExColor: [],
      rowsInColor: [],
      rowsAccy: [],
      rowsExtra: [],
      fieldsInst: [
        {
          key: "periodNo",
          label: "งวดที่",
        },
        {
          key: "dueDate",
          label: "วันที่ชำระ",
        },
        {
          key: "price",
          label: "ยอดชำระ",
        },
        {
          key: "vatPrice",
          label: "% ภาษี",
        },
        {
          key: "totalPrice",
          label: "สุทธิ",
        },
        {
          key: "action",
          label: "ลบ",
        },
      ],
      fieldsGuarantor: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "customerNameTh",
          label: "ชื่อผู้ค้ำประกัน",
        },
        {
          key: "customerFamilyNameTh",
          label: "นามสกุลผู้ค้ำประกัน",
        },
        {
          key: "action",
          label: "ลบ",
        },
      ],
      rowSaleCondition: [
        {
          scId: "C",
          scName: "เงินสด",
        },
        {
          scId: "I",
          scName: "เช่าซื้อ / ผ่อน",
        },
        {
          scId: "CD",
          scName: "สด / เครดิต",
        },
        {
          scId: "OI",
          scName: "เช่าซื้อสถาบันอื่น ๆ",
        },
      ],
      postType: [
        {
          ptId: "R",
          nameTh: "รับเอง",
        },
        {
          ptId: "S",
          nameTh: "จัดส่ง",
        },
      ],
      taxTypeOptions: [
        {
          ttId: "T",
          nameTh: "มีภาษี",
        },
        {
          ttId: "NT",
          nameTh: "ไม่มีภาษี",
        },
      ],
      searchVmCode: "",
      searchVmName: "",
      searchVmVin: "",

      activity: "",

      bookingAccy: [],
      bookingPm: [],
      bookingOth: [],
      rowsGuarantor: [],
      rowsInstallment: [],
      rowsInst: [],

      ttAccy: 0,
      ttPm: 0,
      ttOTH: 0,

      totalPricex: 0,
      bookingTotalPrice: 0,
      bookingPartTotalPrice: 0,
      bookingPartDcPer: 0,
      bookingPartDcPrice: 0,
      bookingPartNetPrice: 0,
      bookingExtraTotalPrice: 0,
      bookingExtraDcPer: 0,
      bookingExtraDcPrice: 0,
      bookingExtraNetPrice: 0,

      vehicleTotalPrice: 0,
      vehicleDcPer: 0,
      vehicleDcPrice: 0,
      vehicleNetPrice: 0,
      partTotalPrice: 0,
      partDcPer: 0,
      partDcPrice: 0,
      partNetPrice: 0,
      extraTotalPrice: 0,
      extraDcPer: 0,
      extraDcPrice: 0,
      extraNetPrice: 0,
      totalPrice: 0,
      dcPer: 0,
      dcPrice: 0,
      netPrice: 0,
      vatPer: 7,
      vatPrice: 0,
      grandTotalPrice: 0,
      saleCondition: "",
      downPayment: 0,
      totalFinancing: 0,
      fiId: "",
      interest: 0,
      numPeriods: 0,
      installment: 0,
      note: "",
      prodId: "",
      actId: "",
      vehicleYear: "",
      ppCusId: "",
      cusSrcId: "",
      downPaymentPercent: 0,
      proCampaignId: "",
      deposit: 0,
      dueDeliveryDate: "",
      eventId: "",
      croId: "",
      saleNote: "",
      exteriorColorId: "",
      interiorColorId: "",
      campaignId: "",
      statusBooking: "",
      accyCode: "",
      partbookingDt: [],
      partbookingDtSum: [],
      exCode: "",
      selectedExtra: "",
      ex: "",
      recommenderId: "",
      addrId: "",
      vehicleId: "",
      referralFee: 0,
      licensePlateDelivery: "",
      productDelivery: "",
      taxType: "",
      installmentDate: "",
      rowsVm: [],
      rowsArr: [],
      rowsCategory: [],
      urlShow: "api/vehicle-booking/file",
      urlUpload: "api/vehicle-booking/file/upload",
      urlFullsize: "api/vehicle-booking/file/full-size",
      urlDelete: "api/vehicle-booking/file/delete",
      paramsShow: {
        params: {
          bookingId: this.$route.params.bookingId,
        },
      },
      paramsDel: {
        data: {
          bookingId: this.$route.params.bookingId,
          fileId: "",
        },
      },
      paramsFull: {
        params: {
          bookingId: this.$route.params.bookingId,
          fileId: "",
        },
      },
      paramsSending: "bookingId"
    };
  },
  validations: {
    deposit:{
      required
    },
    bookingDate: {
      required,
    },
    branch: {
      required,
    },
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
    prodId: {
      required,
    },
    activity: {
      required,
    },
    exteriorColorId: {
      required,
    },
    interiorColorId: {
      required,
    },
    campaignId: {},
    vehicleTotalPrice: {
      required,
    },
    vehicleDcPer: {
      required,
    },
    vehicleDcPrice: {
      required,
    },
    vehicleNetPrice: {
      required,
    },
    downPayment: {
      required,
    },
    totalFinancing: {
      required,
    },
    saleCondition: {
      required,
    },
    numPeriods: {
      required,
    },
    installment: {
      required,
    },
    interest: {
      required,
    },
    fiId: {},
    note: {},
    recommenderId: {},
    addrId: {},
    referralFee: {
      required,
    },
    licensePlateDelivery: {
      required,
    },
    productDelivery: {
      required,
    },
    taxType: {
      required,
    },
    installmentDate: {},
    vehicleId: {
      
    },
    partTotalPrice: {
      required,
    },
    partDcPer: {
      required,
    },
    partDcPrice: {
      required,
    },
    partNetPrice: {
      required,
    },
    extraTotalPrice: {
      required,
    },
    extraDcPer: {
      required,
    },
    extraDcPrice: {
      required,
    },
    extraNetPrice: {
      required,
    },
    ttAccy: {
      required,
    },
    ttOTH: {
      required,
    },
    bookingVatPer: {
      required,
    },
    bookingDcPer: {
      required,
    },
    bookingNetPrice: {
      required,
    },
    bookingVatPrice: {
      required,
    },
    bookingGrandTotalPrice: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    // this.getCategory();
  },
  created() {
    this.getLocalData();
    this.getFin();
    this.showBookingDetails();
  },
  methods: {
    popupModalVm() {
      this.$refs["modalVm"].show();
    },
    onRowSelectedVm(data) {
      console.log(data);
      this.vehicleId = data[0];
      console.log("Vehicle=>", this.vehicleId);
      this.$refs["modalVm"].hide();
    },
    popupModalProduct() {
      this.$refs["modalProd"].show();
    },
    onRowSelectedProd(data) {
      // console.log(data);
      this.vehicleTotalPrice = parseInt(data[0].price);
      // this.vehicleTotalPrice = parseInt(data[0].price);
      this.calTotal();
      this.prodId = data[0];
      this.getVehicle();
      this.$refs["modalProd"].hide();
    },
    PutStatusBooking() {
      useNetw
        .put("api/vehicle-booking/submit", {
          bookingId: this.$route.params.bookingId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.showBooking();
          this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.data),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },
    confirmPutstatusBooking() {
      Swal.fire({
        title: "ต้องการยืนยันใบจองใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.PutStatusBooking();
        }
      });
    },
    changeSc(scId) {
      if (scId == "C") {
        //console.log(scId);
        this.disabledWsc = "dis";
        this.fiId = [];
        this.downPayment = 0.0;
        this.qnVatPer = 0.0;
        this.interest = 0.0;
        (this.numPeriods = 0), (this.installment = 0.0);
        this.totalFinancing = 0.0;
      } else {
        this.disabledWsc = "";
      }
    },
    filterFi(id) {
      let result;
      this.rowsFin.filter(function(item) {
        if (item.fiId == id) {
          //console.log(item.nameTh);
          // this.saleCondition.scName = item.scName
          //console.log('Fi',item);
          result = item.nameTh;
        }
      });
      return result;
    },
    filterCdt(id) {
      let result;
      this.rowSaleCondition.filter(function(item) {
        if (item.scId == id) {
          //console.log(item.scName);
          // this.saleCondition.scName = item.scName
          result = item.scName;
        }
      });
      return result;
    },
    customLabelEx({ extraCode, nameEn, price }) {
      return `[${extraCode}]  ${nameEn} : (${price} บาท)`;
    },
    customLabelCustomerArr({
      buildingNumber,
      districtNameTh, 
      amphurNameEn,
      provinceNameTh,
      postCode,
    }) {
      return `บ้านเลขที่ ${buildingNumber == null ? "-" : buildingNumber}
      ตำบล ${districtNameTh == null ? "-" : districtNameTh} ,
      อำเภอ ${amphurNameEn == null ? "-" : amphurNameEn},
       ${provinceNameTh == null ? "-" : provinceNameTh} ,
       ${postCode == null ? "-" : postCode}`;
    },
    customLabelVehicle({
      stockNameTh,
      vin,
      productNameTh,
      engineNumber,
      chassisNumber,
      exteriorColorNameTh,
    }) {
      return `[${!stockNameTh ? "-" : stockNameTh}]

      ${productNameTh}
      - สีภายนอก : ${exteriorColorNameTh == null ? "-" : exteriorColorNameTh},
      เลขตัวถัง : ${vin == null ? "-" : vin} ,
        เลขเครื่อง :${engineNumber == null ? "-" : engineNumber},
        เลขคัสซี : ${chassisNumber == null ? "-" : chassisNumber}`;
    },
    getRecommenderId() {
      // this.overlayFlag = true;
      //   // console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            nameTh: this.recommenderId.nameTh,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCustomers = response.data.data;
          //console.log("CUS=>", this.rowsCustomers);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getCustomerArr() {
      // this.overlayFlag = true;
      //   // console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-customer-address", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            cusId: this.nameTh.cusId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          
          this.rowsArr = response.data.data;
          // console.log(this.rowsArr);
          // console.log("ARR=>", this.rowsArr);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getVehicle() {
      this.overlayFlag = true;
      //   // console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-stock-vehicle", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
            prodId: this.prodId.prodId,
            modelNameTh: this.searchVmName,
            vin: this.searchVmVin,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsVm = response.data.data;
          // console.log("Vehicle=>", this.rowsVm);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    processInst() {
      const formDataG = new FormData();
      formDataG.append("bookingId", this.$route.params.bookingId);

      useNetw
        .post("api/vehicle-booking/installment/process", formDataG)
        .then((response) => {
          this.showAlert(response.data.message);
          this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.validationMessage[0]),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    postGuarantor(customerG) {
      // console.log("guarantor=>", customerG);

      const formDataG = new FormData();
      formDataG.append("bookingId", this.$route.params.bookingId);
      formDataG.append("cusId", customerG.cusId);

      useNetw
        .post("api/vehicle-booking/guarantor/store", formDataG)
        .then((response) => {
          this.showAlert(response.data.message);
          this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    changeType(type) {
      this.typebookingDetails = type;
      // console.log(this.typebookingDetails);
    },
    postEx(ex) {
      // let arr = [];
      // this.bookingDetails.forEach((item) => {
      //   arr.push(item.extraId);
      // });
      // if (!arr.includes(ex.extraId)) {
      //   this.bookingDetails.push(ex);
      // }

      this.calTotal();

      const formDataPart = new FormData();
      formDataPart.append("bookingId", this.$route.params.bookingId);
      // formDataPart.append("partId", null);
      formDataPart.append("extraId", ex.extraId);
      formDataPart.append("amount", this.amountAccy);
      formDataPart.append("pricePerOne", ex.price);
      formDataPart.append("totalPrice", ex.price * this.amountAccy);
      formDataPart.append("dcPrice", ex.price * (this.dcPerAccy / 100));
      formDataPart.append("dcPer", this.dcPerAccy);
      formDataPart.append("netPrice", ex.price * this.amountAccy);
      formDataPart.append("vatPer", this.vatPer);
      formDataPart.append("vatPrice", 0);
      formDataPart.append("grandTotalPrice", ex.price * this.amountAccy);

      formDataPart.append("type", "OTH");

      formDataPart.append("bookingPartTotalPrice", this.partTotalPrice);
      formDataPart.append("bookingPartDcPer", this.partDcPer);
      formDataPart.append("bookingPartDcPrice", this.partDcPrice);
      formDataPart.append("bookingPartNetPrice", this.partNetPrice);
      formDataPart.append(
        "bookingExtraTotalPrice",
        this.extraTotalPrice + ex.price * this.amountAccy
      );
      formDataPart.append("bookingExtraDcPer", this.extraDcPer);
      formDataPart.append("bookingExtraDcPrice", this.extraDcPrice);
      formDataPart.append(
        "bookingExtraNetPrice",
        this.extraNetPrice + ex.price * this.amountAccy
      );
      formDataPart.append("grandTotalPrice", this.bookingTotalPrice);

      formDataPart.append("bookingTotalPrice", this.bookingTotalPrice);
      formDataPart.append("bookingDcPer", this.bookingDcPer);
      formDataPart.append("bookingDcPrice", this.bookingDcPrice);
      formDataPart.append("bookingNetPrice", this.bookingNetPrice);
      formDataPart.append("bookingVatPer", this.bookingVatPer);
      formDataPart.append("bookingVatPrice", this.bookingVatPrice);

      formDataPart.append(
        "bookingGrandTotalPrice",
        parseFloat(this.bookingGrandTotalPrice)
      );

      useNetw
        .post("api/vehicle-booking/detail/store", formDataPart)
        .then((response) => {
          this.$refs.modalAmoutEx.hide();
          this.showAlert(response.data.message);
          this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs["modalAmout"].hide();
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    calAll() {
      this.bookingDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
          //console.log('this.ttAccy',this.ttAccy);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
          //console.log('this.ttOTH',this.ttOTH);
        }
        //console.log("ACCY=>", this.bookingAccy);
      });
    },
    delInst(iperiodId) {
      // console.log(iperiodId);
      useNetw
        .delete("api/vehicle-booking/installment/delete", {
          data: {
            iperiodId: iperiodId,
            // partId: rowRT.partId,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    delGuarantor(guarantorId) {
      // console.log(guarantorId);
      useNetw
        .delete("api/vehicle-booking/guarantor/delete", {
          data: {
            guarantorId: guarantorId,
            // partId: rowRT.partId,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    conformDelInst(iperiodId) {
      //console.log(guarantorId);

      Swal.fire({
        title: "ต้องการลบงวดการชำระใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delInst(iperiodId);
        }
      });
    },

    confirmDelGuarantor(guarantorId) {
      //console.log(guarantorId);

      Swal.fire({
        title: "ต้องการลบผู้ค้ำประกันใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delGuarantor(guarantorId);
        }
      });
    },
    delAccy(rowRT, index) {
      // this.selectDataPO = rowsRC.item;

      this.bookingDetails.splice(index, 1);
      this.calTotal();
      //console.log('this.bookingPartDcPer[0]====',this.bookingPartDcPer);
      //// console.log('RT ====> 0',rowRT);
      useNetw
        .delete("api/vehicle-booking/detail/delete", {
          data: {
            bookingdId: rowRT.bookingdId,
            // partId: rowRT.partId,

            bookingPartTotalPrice: this.partTotalPrice,
            bookingPartDcPer: this.partDcPer,
            bookingPartDcPrice: this.partDcPrice,
            bookingPartNetPrice: this.partNetPrice,
            bookingExtraTotalPrice: this.extraTotalPrice,
            bookingExtraDcPer: this.extraDcPer,
            bookingExtraDcPrice: this.extraDcPrice,
            bookingExtraNetPrice: this.extraNetPrice,

            bookingTotalPrice: this.bookingTotalPrice,
            bookingDcPer: this.bookingDcPer,
            bookingDcPrice: this.bookingDcPrice,
            bookingNetPrice: this.bookingNetPrice,
            bookingVatPer: this.bookingVatPer,
            bookingVatPrice: this.bookingVatPrice,
            bookingGrandTotalPrice: parseFloat(this.bookingGrandTotalPrice),
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.showBookingDetails();
          // this.getTotalPriceRT;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
    ConfirmDelAccy(rowsAccy, index) {
      //console.log(rowsRT[index]);
      //// console.log('DEL==>',rowsRC.item.poId);
      Swal.fire({
        title: "ต้องการลบใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delAccy(rowsAccy[index], index);
        }
      });
    },
    calculatePbookingDt(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(this.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.partbookingDt = part;

      var bookingPartTotalPrice = totalPriceCal;
      var bookingPartDcPer = 0;
      var bookingPartDcPrice = 0;
      var bookingPartNetPrice = part.netPrice;
      var bookingExtraTotalPrice = 0;
      var bookingExtraDcPer = 0;
      var bookingExtraDcPrice = 0;
      var bookingExtraNetPrice = 0;

      this.bookingDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy + parseFloat(item.netPrice);
          //console.log('this.ttAccy',this.ttAccy);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH + parseFloat(item.netPrice);
          //console.log('this.ttOTH',this.ttOTH);
        }
        //console.log("ACCY=>", this.bookingAccy);
      });

      if (this.typebookingDetails == "ACCY") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typebookingDetails == "PM") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typebookingDetails == "OTH") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      }
      var x = [];
      x.push({
        bookingPartTotalPrice: bookingPartTotalPrice,
        bookingPartDcPer: bookingPartDcPer,
        bookingPartDcPrice: bookingPartDcPrice,
        bookingPartNetPrice: bookingPartNetPrice,
        bookingExtraTotalPrice: bookingExtraTotalPrice,
        bookingExtraDcPer: bookingExtraDcPer,
        bookingExtraDcPrice: bookingExtraDcPrice,
        bookingExtraNetPrice: bookingExtraNetPrice,
      });
      this.partbookingDtSum = x;
      this.bookingTotalPricex = totalPriceCal;

      this.calTotal();
    },

    calculateBookingDt(part) {
      let totalPriceCal =
        parseFloat(part.pricePerOne) * parseFloat(this.amountAccy);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      calNetPrice = parseFloat(part.totalPrice) - parseFloat(part.dcPrice);
      if (!isNaN(calNetPrice)) {
        part.netPrice = calNetPrice.toFixed(2);
      }

      let calVatPrice = 0;

      calVatPrice = part.netPrice * parseFloat(this.vatPer / 100);
      if (!isNaN(calVatPrice)) {
        part.vatPrice = calVatPrice.toFixed(2);
      }

      let calGrandTotalPrice =
        parseFloat(part.netPrice) + parseFloat(part.vatPrice);
      if (!isNaN(calGrandTotalPrice)) {
        part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
      }

      this.partbookingDt = part;

      var bookingPartTotalPrice = totalPriceCal;
      var bookingPartDcPer = 0;
      var bookingPartDcPrice = 0;
      var bookingPartNetPrice = part.netPrice;
      var bookingExtraTotalPrice = 0;
      var bookingExtraDcPer = 0;
      var bookingExtraDcPrice = 0;
      var bookingExtraNetPrice = 0;

      this.bookingDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
          //console.log('this.ttAccy',this.ttAccy);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
          //console.log('this.ttOTH',this.ttOTH);
        }
        //console.log("ACCY=>", this.bookingAccy);
      });

      if (this.typebookingDetails == "ACCY") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typebookingDetails == "PM") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      } else if (this.typebookingDetails == "OTH") {
        bookingPartTotalPrice = totalPriceCal;
        bookingPartDcPer = 0;
        bookingPartDcPrice = 0;
        bookingPartNetPrice = part.netPrice;
        bookingExtraTotalPrice = this.ttOTH;
        bookingExtraDcPer = 0;
        bookingExtraDcPrice = 0;
        bookingExtraNetPrice = this.ttOTH;
      }
      var x = [];
      x.push({
        bookingPartTotalPrice: bookingPartTotalPrice,
        bookingPartDcPer: bookingPartDcPer,
        bookingPartDcPrice: bookingPartDcPrice,
        bookingPartNetPrice: bookingPartNetPrice,
        bookingExtraTotalPrice: bookingExtraTotalPrice,
        bookingExtraDcPer: bookingExtraDcPer,
        bookingExtraDcPrice: bookingExtraDcPrice,
        bookingExtraNetPrice: bookingExtraNetPrice,
      });
      this.partbookingDtSum = x;
      this.bookingTotalPricex = totalPriceCal;

      this.calTotal();
    },
    calTotal() {

      this.calProd();
      this.ttAccy = 0;
      this.ttOTH = 0;

      this.bookingDetails.forEach((item) => {
        if (item.type == "ACCY") {
          this.ttAccy += parseFloat(item.netPrice);
          // console.log("this.ttAccy", this.ttAccy);
        } else if (item.type == "PM") {
          this.ttPm = 0;
        } else {
          this.ttOTH += parseFloat(item.netPrice);
        }
      });
      this.partTotalPrice = parseFloat(this.ttAccy);
      this.extraTotalPrice = parseFloat(this.ttOTH);

      this.vehicleDcPrice =
        parseFloat(this.vehicleTotalPrice) *
        parseFloat(this.vehicleDcPer / 100);
      this.vehicleNetPrice =
        parseFloat(this.vehicleTotalPrice) - parseFloat(this.vehicleDcPrice);
      this.partDcPrice =
        parseFloat(this.ttAccy) * parseFloat(this.partDcPer / 100);
      this.partNetPrice =
        parseFloat(this.ttAccy) - parseFloat(this.partDcPrice);
      this.extraDcPrice =
        parseFloat(this.ttOTH) * parseFloat(this.extraDcPer / 100);
      this.extraNetPrice =
        parseFloat(this.ttOTH) - parseFloat(this.extraDcPrice);

      var bookingTotalPrice,
        bookingDcPrice,
        bookingNetPrice,
        bookingVatPrice,
        bookingGrandTotalPrice;
      // console.log(this.vehicleNetPrice);

      bookingTotalPrice =
        parseFloat(this.vehicleNetPrice) +
        parseFloat(this.partNetPrice) +
        parseFloat(this.extraNetPrice);
      // console.log("bookingTotalPrice", bookingTotalPrice);
      this.bookingTotalPrice = parseFloat(bookingTotalPrice);
      bookingDcPrice =
        parseFloat(this.bookingDcPer / 100) * parseFloat(bookingTotalPrice);
      this.bookingDcPrice = parseFloat(bookingDcPrice);
      bookingNetPrice =
        parseFloat(bookingTotalPrice) - parseFloat(this.bookingDcPrice);
      this.bookingNetPrice = parseFloat(bookingNetPrice);
      bookingVatPrice =
        parseFloat(this.bookingVatPer / 100) * parseFloat(bookingNetPrice);
      this.bookingVatPrice = parseFloat(bookingVatPrice);
      bookingGrandTotalPrice =
        parseFloat(bookingNetPrice) + parseFloat(bookingVatPrice);
      this.bookingGrandTotalPrice = parseFloat(bookingGrandTotalPrice);

      this.totalFinancing =
        parseFloat(this.bookingGrandTotalPrice) - parseFloat(this.downPayment);

      this.changeSc(this.saleCondition.scId);

      //console.log('ttAccy in func =>', this.ttAccy);
    },
    putAccy(
      bookingdid,
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      vatPer,
      vatPrice,
      grandTotalPrice,
      extraId
    ) {
      // this.amountRT = amount;
      // console.log(
      //   bookingdid,
      //   partId,
      //   sellPrice,
      //   amount,
      //   totalPrice,
      //   dcPer,
      //   dcPrice,
      //   netPrice,
      //   vatPer,
      //   vatPrice,
      //   grandTotalPrice,
      //   extraId
      // );
      (this.amountAccy = amount), this.calTotal();
      useNetw
        .put("api/vehicle-booking/detail/update", {
          bookingId: this.$route.params.bookingId,

          bookingdId: bookingdid,
          partId: parseInt(partId),
          amount: parseInt(amount),
          extraId: extraId,
          pricePerOne: sellPrice,
          totalPrice: totalPrice,
          dcPer: dcPer,
          dcPrice: dcPrice,
          netPrice: netPrice,
          vatPer: vatPer,
          vatPrice: vatPrice,
          grandTotalPrice: parseInt(amount) * sellPrice,
          type: this.typebookingDetails,
          // pickingAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,
          // pickingAccruedAmount: this.selectDataRT.pickingAccruedAmount - this.amountRT,
          bookingPartTotalPrice: this.partTotalPrice,
          bookingPartDcPer: this.partDcPer,
          bookingPartDcPrice: this.partDcPrice,
          bookingPartNetPrice: this.partNetPrice,
          bookingExtraTotalPrice: this.extraTotalPrice,
          bookingExtraDcPer: this.extraDcPer,
          bookingExtraDcPrice: this.extraDcPrice,
          bookingExtraNetPrice: this.extraNetPrice,

          bookingTotalPrice: this.bookingTotalPrice,
          bookingDcPer: this.bookingDcPer,
          bookingDcPrice: this.bookingDcPrice,
          bookingNetPrice: this.bookingNetPrice,
          bookingVatPer: this.bookingVatPer,
          bookingVatPrice: this.bookingVatPrice,
          bookingGrandTotalPrice: parseFloat(this.bookingGrandTotalPrice),
        })
        .then((response) => {
          this.showAlert(response.data.message);

          this.showBookingDetails;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },

    postAccy(part) {
      let arr = [];
      this.bookingDetails.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.bookingDetails.push(part);
      }

      this.calculateBookingDt(part);

      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = this.selectedPart.sellPrice * this.amountAccy;
      //console.log("TT", part.totalPrice);
      part.netPrice;
      this.calTotal();

      const formDataPart = new FormData();
      formDataPart.append("bookingId", this.$route.params.bookingId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", this.amountAccy);
      formDataPart.append("pricePerOne", part.pricePerOne);
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.totalPrice * (this.dcPerAccy / 100));
      formDataPart.append("dcPer", this.dcPerAccy);
      formDataPart.append("netPrice", part.totalPrice);
      formDataPart.append("vatPer", this.vatPer);
      formDataPart.append("vatPrice", part.vatPrice);
      formDataPart.append("grandTotalPrice", part.totalPrice);

      formDataPart.append("type", this.typebookingDetails);

      formDataPart.append(
        "bookingPartTotalPrice",
        this.partTotalPrice + part.totalPrice
      );
      formDataPart.append("bookingPartDcPer", this.partDcPer);
      formDataPart.append("bookingPartDcPrice", this.partDcPrice);
      formDataPart.append(
        "bookingPartNetPrice",
        this.partNetPrice + part.totalPrice
      );
      formDataPart.append("bookingExtraTotalPrice", this.extraTotalPrice);
      formDataPart.append("bookingExtraDcPer", this.extraDcPer);
      formDataPart.append("bookingExtraDcPrice", this.extraDcPrice);
      formDataPart.append("bookingExtraNetPrice", this.extraNetPrice);
      formDataPart.append("grandTotalPrice", this.bookingTotalPrice);

      formDataPart.append("bookingTotalPrice", this.bookingTotalPrice);
      formDataPart.append("bookingDcPer", this.bookingDcPer);
      formDataPart.append("bookingDcPrice", this.bookingDcPrice);
      formDataPart.append("bookingNetPrice", this.bookingNetPrice);
      formDataPart.append("bookingVatPer", this.bookingVatPer);
      formDataPart.append("bookingVatPrice", this.bookingVatPrice);

      formDataPart.append(
        "bookingGrandTotalPrice",
        parseFloat(this.bookingGrandTotalPrice)
      );

      useNetw
        .post("api/vehicle-booking/detail/store", formDataPart)
        .then((response) => {
          this.showAlert(response.data.message);
          this.showBookingDetails();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs["modalAmout"].hide();
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    showModal(option) {
      if (option != null) {
        this.selectedPart = option;
        this.part = option;
        this.part.partCode = option.partCode;
        this.part.pricePerOne = option.sellPrice;
        this.part.totalPrice = option.sellPrice;
        this.part.netPrice = option.sellPrice;
        this.part.nameTh = option.nameTh;
        this.part.partId = option.partId;
        this.$refs.modalAmout.show(option);
      }
    },

    showModalEx(option) {
      if (option != null) {
        this.selectedExtra = option;
        this.ex = option;
        this.ex.partCode = option.partCode;
        this.ex.pricePerOne = option.sellPrice;
        this.ex.totalPrice = option.sellPrice;
        this.ex.netPrice = option.sellPrice;
        this.ex.nameTh = option.nameTh;
        this.ex.partId = option.partId;
        this.$refs.modalAmoutEx.show(option);
      }
    },
    showBookingDetails() {
      this.bookingAccy = [];
      this.bookingPm = [];
      this.bookingOth = [];
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-booking/detail", {
          params: {
            bookingId: this.$route.params.bookingId,
          },
        })
        .then((response) => {
          //console.log("booking Details =>", response.data.data);
          this.bookingDetails = response.data.data;
          this.bookingDetails.forEach((item) => {
            if (item.type == "ACCY") {
              this.bookingAccy.push(item);
            } else if (item.type == "PM") {
              this.bookingPm.push(item);
            } else if (item.type == "OTH") {
              this.bookingOth.push(item);
            }
            //console.log("ACCY=>", this.bookingAccy);
          });
          this.getGuarantor();
          this.getInstallment();
          this.calTotal();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    showBooking() {
      //console.log(this.$route.params.bookingId);
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-booking/show", {
          params: {
            bookingId: this.$route.params.bookingId,
          },
        })
        .then((response) => {
          this.getProduct();
          this.getCustomer();
          this.getActivity();
          this.getCampaign();
          this.getExColor();
          this.getInColor();
          this.getAccy();
          this.getExtra();
          this.getGuarantor();
          this.getInstallment();
          this.getVehicle();
          this.getProspect();
          this.getCusSrc();
          this.getEvents();
          this.getCro();

          this.statusBooking = response.data.data.status;
          //console.log("Show =>", response.data.data);
          this.branch = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          (this.nameTh = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          }),
            (this.bookingDate = response.data.data.bookingDate);
          this.activity = {
            actId: response.data.data.actId,
            nameTh: response.data.data.activityNameTh,
          };
          this.prodId = {
            prodId: response.data.data.prodId,
            prodCode: response.data.data.brandNameEn,
            nameTh: response.data.data.productNameTh,
            nameEn: response.data.data.productNameEn,
          };
          this.exteriorColorId = {
            exteriorColorId: response.data.data.exteriorColorId,
            nameTh: response.data.data.exteriorColorNameTh,
            nameEn: response.data.data.exteriorColorNameEn,
          };
          this.interiorColorId = {
            interiorColorId: response.data.data.interiorColorId,
            nameTh: response.data.data.interiorColorNameTh,
            nameEn: response.data.data.interiorColorNameEn,
          };
          this.campaignId = {
            campaignId: response.data.data.campaignId,
            nameTh: response.data.data.campaignNameTh,
            nameEn: response.data.data.campaignNameEn,
          };
          this.vehicleTotalPrice = response.data.data.vehicleTotalPrice;
          this.vehicleDcPer = response.data.data.vehicleDcPer;
          this.vehicleDcPrice = response.data.data.vehicleDcPrice;
          this.vehicleNetPrice = response.data.data.vehicleNetPrice;

          this.saleCondition = {
            scId: response.data.data.saleCondition,
            scName: this.filterCdt(response.data.data.saleCondition),
          };
          this.downPayment = response.data.data.downPayment;
          this.totalFinancing = response.data.data.totalFinancing;
          this.fiId = {
            fiId: response.data.data.fiId,
            nameTh: this.filterFi(response.data.data.fiId),
          };
          this.interest = response.data.data.interest;
          (this.numPeriods = response.data.data.numPeriods),
            (this.installment = response.data.data.installment),
            (this.note =
              response.data.data.note == null ? "" : response.data.data.note);

          this.recommenderId = {
            cusId: response.data.data.recommenderId,
            nameTh: response.data.data.recommenderNameTh,
            familyNameTh: response.data.data.recommenderFamilyNameTh,
          };
          this.addrId = {
            addrId: response.data.data.addrId,
            fullAddress: response.data.data.fullAddress
          };
          this.installmentDate = response.data.data.installmentDate;
          this.vehicleId = {
            vehicleId: response.data.data.vehicleId,
            stockNameTh: response.data.data.stockNameTh,
            vin: response.data.data.vin,
            chassisNumber: response.data.data.chassisNumber,
            engineNumber: response.data.data.engineNumber,
            productNameTh: response.data.data.productNameTh,
            exteriorColorNameTh: response.data.data.exteriorColorNameTh,
          };
          // console.log('vehicleId=> ', this.vehicleId);
          this.referralFee = response.data.data.referralFee;
          this.licensePlateDelivery = {
            ptId: response.data.data.licensePlateDelivery,
            nameTh:
              response.data.data.licensePlateDelivery == "S"
                ? "จัดส่ง"
                : "รับเอง",
          };
          this.productDelivery = {
            ptId: response.data.data.productDelivery,
            nameTh:
              response.data.data.productDelivery == "S" ? "จัดส่ง" : "รับเอง",
          };
          this.taxType = {
            ttId: response.data.data.taxType,
            nameTh:
              response.data.data.taxType == "T" ? "มีภาษี" : "ไม่มีมีภาษี",
          };

          this.vehicleYear = {
            id: response.data.data.vehicleYear,
          }
          this.ppCusId = {
            ppCusId: response.data.data.ppCusId,
            nameTh: response.data.data.ppCusNameTh
          }
          this.cusSrcId = {
            cusSrcId: response.data.data.cusSrcId,
            nameTh: response.data.data.customerSourcNameTh
          }
          this.downPaymentPercent = response.data.data.downPaymentPercent
          this.proCampaignId= {
            proCampaignId: response.data.data.proCampaignId,
            nameTh: response.data.data.campaignNameTh
          }
          this.deposit = response.data.data.deposit,
          this.dueDeliveryDate = response.data.data.dueDeliveryDate
          this.eventId = {
            eventId: response.data.data.eventId,
            nameTh: response.data.data.eventNameTh
          }
          this.croId = {
            userId: response.data.data.croId,
            nameTh: response.data.data.croNameTh
          }
          this.saleNote = response.data.data.saleNote

          this.getCustomerArr();
          this.calTotal();

          // referralFee
          // licensePlateDelivery
          // productDelivery
          // taxType
          //
          this.overlayFlag = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    setProduct(vehicle) {
      this.vehicleTotalPrice = parseInt(vehicle.price);
      this.calTotal();
    },
    calProd() {
      var dcPrice;
      var netPrice;
      var totalFinancing;
      var installments;
      var dPerYear;

      if (!isNaN()) {
        //console.log("No");
      } else {
        dcPrice =
          this.vehicleTotalPrice * (parseFloat(this.vehicleDcPer) / 100);
        netPrice = this.vehicleTotalPrice - dcPrice;
        totalFinancing = netPrice - parseFloat(this.downPayment);
        dPerYear = ((totalFinancing * (1 + this.interest / 100)) - totalFinancing) * (this.numPeriods/12);
        // dPerYear = ((totalFinancing * (1 + this.interest / 100)) /  (this.numPeriods/12));
        
        installments =
          // (totalFinancing / this.numPeriods * (1 + this.interest / 100));
          parseFloat(dPerYear + totalFinancing) / this.numPeriods
        
        
        // console.log(installments);
        this.vehicleDcPrice = dcPrice;
        this.vehicleNetPrice = netPrice;
        this.totalFinancing = totalFinancing;
        this.installment = installments.toFixed(2);
      }
    },

    customLabelProduct({ prodCode, nameEn }) {
      return `[${!prodCode ? "-" : prodCode}]  ${!nameEn ? "" : nameEn}`;
    },
    customLabelCustomer({ nameTh, familyNameTh }) {
      return `${!nameTh ? "" : nameTh}  ${!familyNameTh ? "" : familyNameTh}`;
    },
    customLabelAccy({ partCode, nameTh }) {
      return `[${partCode != null ? " " + partCode : ""}] - ${
        nameTh != null ? nameTh : ""
      }`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
      //console.log(this.localDataBranch);
    },
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      //console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitBooking();
      }
    },

    getInColor() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-interior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsInColor = response.data.data;
          //console.log("InC=>", this.rowsInColor);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getGuarantor() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/guarantor", {
          params: {
            bookingId: this.$route.params.bookingId,
            // page: this.currentPage,
            // perPage: 100,
            // branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsGuarantor = response.data.data;
          // console.log("rowsGuarantor=>", this.rowsGuarantor);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getInstallment() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/installment", {
          params: {
            bookingId: this.$route.params.bookingId,
            // page: this.currentPage,
            // perPage: 100,
            // branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsInst = response.data.data;
          // console.log("rowsInstallment=>", this.rowsInst);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getExtra() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-extra", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsExtra = response.data.data;
          //console.log("Extra=>", this.rowsExtra);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getExColor() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-exterior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsExColor = response.data.data;
          //console.log("ExC=>", this.rowsExColor);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getCampaign() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-campaign", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCampiagn = response.data.data;
          //console.log("CAM=>", this.rowsCampiagn);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getActivity() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-activity", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsAct = response.data.data;
          //console.log("ACT=>", this.rowsAct);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getFin() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-financial-institution", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsFin = response.data.data;
          this.showBooking();
          //console.log("FIN=>", this.rowsFin);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getCustomer() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-customer", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsCustomers = response.data.data;
          //console.log("CUS=>", this.rowsCustomers);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getAccy() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.branchId,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowsAccy = response.data.data;
          //console.log("rowsAccy=>", this.rowsAccy);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    getProduct() {
      this.overlayFlag = true;
      //   //console.log('Success ');
      useNetw
        .get("api/vehicle-booking/branch-product", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId,
            nameTh: this.searchNameProd,
            prodCode: this.searchProdcode,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowProducts = response.data.data;
          //console.log("PC=>", this.rowProducts);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    submitBooking: function() {
      console.log('CRO=>',this.croId.userId);
      console.log('Recomen-> ',this.recommenderId);
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/vehicle-booking/update", {
          bookingId: this.$route.params.bookingId,
          branchId: this.branch.branchId,
          cusId: this.nameTh.cusId,
          bookingDate: this.bookingDate,
          vehicleTotalPrice: this.vehicleTotalPrice,
          vehicleDcPer: this.vehicleDcPer,
          vehicleDcPrice: this.vehicleDcPrice,
          vehicleNetPrice: this.vehicleNetPrice,
          partTotalPrice: this.partTotalPrice,
          partDcPer: this.partDcPer,
          partDcPrice: this.partDcPrice,
          partNetPrice: this.partNetPrice,
          extraTotalPrice: this.extraTotalPrice,
          extraDcPer: this.extraDcPer,
          extraDcPrice: this.extraDcPrice,
          extraNetPrice: this.extraNetPrice,
          totalPrice: this.bookingTotalPrice,
          dcPer: this.bookingDcPer,
          dcPrice: this.bookingDcPrice,
          netPrice: this.bookingNetPrice,
          vatPer: this.bookingVatPer,
          vatPrice: this.bookingVatPrice,

          grandTotalPrice: parseFloat(this.bookingGrandTotalPrice),
          saleCondition: this.saleCondition.scId,
          downPayment: this.downPayment,
          totalFinancing: this.totalFinancing,
          fiId: this.fiId.fiId,
          interest: this.interest,
          numPeriods: this.numPeriods,
          installment: this.installment,
          note: this.note,
          prodId: this.prodId.prodId,
          actId: this.activity.actId,
          exteriorColorId: this.exteriorColorId.exteriorColorId,
          interiorColorId: this.interiorColorId.interiorColorId,
          campaignId: this.campaignId.campaignId,
          installmentDate: this.installmentDate,

          recommenderId: this.recommenderId.cusId,
          addrId: this.addrId.addrId,
          vehicleId: this.vehicleId.vehicleId,
          referralFee: this.referralFee,
          licensePlateDelivery: this.licensePlateDelivery.ptId,
          productDelivery: this.productDelivery.ptId,
          taxType: this.taxType.ttId,

          vehicleYear: this.vehicleYear.id,
          ppCusId: this.ppCusId.ppCusId,
          cusSrcId: this.cusSrcId.cusSrcId, 
          downPaymentPercent: this.downPaymentPercent,
          proCampaignId: this.campaignId.campaignId,
          deposit: this.deposit,
          dueDeliveryDate: this.dueDeliveryDate,
          eventId: this.eventId.eventId,
          croId: this.croId.userId,
          saleNote: this.saleNote,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "vehicle-booking",
            // params: {
            //   bookingId: response.data.bookingId,
            // },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    onChangeBranch() {
      this.branchId = this.branch.branchId;
      this.getProduct();
    },
    getProspect: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/vehicle-booking/branch-prospect-customer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId,
          },
        })
        .then((response) => {
          this.prospectOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getCusSrc: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/vehicle-booking/branch-customer-source", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId,
          },
        })
        .then((response) => {
          this.cusSrcOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getEvents: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/vehicle-booking/branch-sales-event", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId,
          },
        })
        .then((response) => {
          this.eventOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getCro: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/vehicle-booking/branch-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId,
          },
        })
        .then((response) => {
          this.croOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สาขา<br />
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          @input="onChangeBranch"
                          v-model="branch"
                          :options="localData"
                          label="nameTh"
                          :class="{
                            'is-invalid': submitform && $v.branch.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.branch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="nameTh"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="auto"
                          @search-change="getCustomer"
                          @remove="getCustomer"
                          @input="getCustomerArr"
                          :custom-label="customLabelCustomer"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 p-0"><br>
                      <router-link :to="{ name: 'formcus' }"> &nbsp;
                      <button class="btn btn-primary" > เพิ่ม </button>
                      </router-link>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ :
                        <date-picker
                          :disabled="statusBooking != 'DFT'"
                          v-model="bookingDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid': submitform && $v.bookingDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.bookingDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.bookingDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> กิจกรรมการขาย :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="activity"
                          label="nameTh"
                          type="search"
                          :options="rowsAct"
                          open-direction="bottom"
                          placeholder="กิจกรรมการขาย"
                          :class="{
                            'is-invalid': submitform && $v.activity.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.activity.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.activity.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> รหัสสินค้า :
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          :value="prodId.prodCode"
                        />
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <code> * </code> ชื่อสินค้า :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          @input="setProduct(prodId)"
                          v-model="prodId"
                          label="nameEn"
                          type="search"
                          :options="rowProducts"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          :custom-label="customLabelProduct"
                          :class="{
                            'is-invalid': submitform && $v.prodId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.prodId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.prodId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button
                        @click="popupModalProduct()"
                        class="btn btn-primary"
                        :disabled="statusBooking != 'DFT'"
                      >
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-11">
                      <div class="mb-3 position-relative">
                         รถคันที่เลือก :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="vehicleId"
                          label="nameEn"
                          type="search"
                          :options="rowsVm"
                          open-direction="bottom"
                          placeholder="ชื่อสินค้า"
                          :custom-label="customLabelVehicle"
                          :class="{
                            'is-invalid': submitform && $v.vehicleId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.vehicleId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vehicleId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <button
                        @click="popupModalVm()"
                        class="btn btn-primary"
                        :disabled="statusBooking != 'DFT'"
                      >
                        <i class="uil uil-search-plus"></i>
                      </button>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายนอก :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="exteriorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsExColor"
                          open-direction="bottom"
                          placeholder="สีภายนอก"
                          :class="{
                            'is-invalid':
                              submitform && $v.exteriorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.exteriorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.exteriorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> สีภายใน :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="interiorColorId"
                          label="nameTh"
                          type="search"
                          :options="rowsInColor"
                          open-direction="bottom"
                          placeholder="สีภายใน"
                          :class="{
                            'is-invalid':
                              submitform && $v.interiorColorId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.interiorColorId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interiorColorId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        แคมเปญ :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="campaignId"
                          label="nameTh"
                          type="search"
                          :options="rowsCampiagn"
                          open-direction="bottom"
                          placeholder="แคมเปญ"
                          :class="{
                            'is-invalid': submitform && $v.campaignId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.campaignId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.campaignId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <label class="d-inline align-items-center"
                          >ปีรถ (ค.ศ):</label
                        >
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="vehicleYear"
                          :options="optionYear"
                          label="id"
                          :show-labels="false"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-1 text-left">
                      &nbsp; <br />
                      <!-- <button
                        class="btn btn-primary"
                        :disabled="statusBooking != 'DFT'"
                      >
                        <i class="uil uil-search-plus"></i>
                      </button> -->
                    </div>
                    <hr />

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงื่อนไขการขาย :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          @input="changeSc(saleCondition.scId)"
                          v-model="saleCondition"
                          label="scName"
                          type="search"
                          :options="rowSaleCondition"
                          open-direction="bottom"
                          placeholder="เงื่อนไขการขาย"
                          :class="{
                            'is-invalid': submitform && $v.saleCondition.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.saleCondition.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.saleCondition.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงินมัจจำ (บาท) :
                        <input
                          :disabled="statusBooking != 'DFT' || disabledWsc == 'dis' "
                          max="100"
                          min="0"
                          @change="calProd()"
                          type="number"
                          style="text-align:right"
                          v-model="deposit"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.deposit.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.deposit.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.deposit.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> เงินดาวน์ (บาท) :
                        <input
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          @change="calTotal()"
                          max="100"
                          min="0"
                          type="number"
                          style="text-align:right"
                          v-model="downPayment"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                         เงินดาวน์ (%) :
                        <input
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          max="100"
                          min="0"
                          type="number"
                          style="text-align:right"
                          v-model="downPaymentPercent"
                          class="form-control"
                         
                        />
                        
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดจัดไฟแนนซ์ (บาท) :
                        <input
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          type="number"
                          style="text-align:right"
                          v-model="totalFinancing"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              submitform && $v.totalFinancing.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.totalFinancing.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.totalFinancing.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> วันที่ชำระงวดแรก :
                        <date-picker
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          v-model="installmentDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          :class="{
                            'is-invalid':
                              submitform && $v.installmentDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.installmentDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.installmentDate.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="mb-3 position-relative">
                        <code> * </code> สถาบันการเงิน :
                        <multiselect
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          v-model="fiId"
                          label="nameTh"
                          type="search"
                          :options="rowsFin"
                          open-direction="bottom"
                          placeholder="สถาบันการเงิน"
                          :class="{
                            'is-invalid': submitform && $v.fiId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.fiId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.fiId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> ดอกเบี้ย/เดือน:
                        <input
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          type="number"
                          style="text-align:right"
                          v-model="interest"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.interest.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.interest.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.interest.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> จำนวนงวด :
                        <input
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          @change="calTotal()"
                          min="0"
                          type="number"
                          style="text-align:right"
                          v-model="numPeriods"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.numPeriods.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.numPeriods.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.numPeriods.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ยอดชำระต่องวด (บาท):
                        <input
                          :disabled="
                            statusBooking != 'DFT' || disabledWsc == 'dis'
                          "
                          type="number"
                          style="text-align:right"
                          v-model="installment"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.installment.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.installment.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.installment.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        วันที่ส่งมอบ :
                        <date-picker
                          :disabled="
                            statusBooking != 'DFT' 
                          "
                          v-model="dueDeliveryDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                          
                        ></date-picker>
                        
                      </div>
                    </div>

                    <hr />
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        แหล่งที่มาลูกค้า :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="cusSrcId"
                          :options="cusSrcOption"
                          label="nameTh"
                          track-by="cusSrcId"
                          :show-labels="false"
                          placeholder="ค้นหาชื่อลูกค้ามุ่งหวัง"
                          @search-change="getCusSrc()"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ชื่อลูกค้ามุ่งหวัง :
                        <!-- <label for="nameTh"></label>: -->
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="ppCusId"
                          :options="prospectOption"
                          label="nameTh"
                          track-by="ppCusId"
                          :show-labels="false"
                          placeholder="ค้นหา"
                          @search-change="getProspect()"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        CRO :
                        <!-- <label for="nameTh"></label>: -->
                        <multiselect
                          
                          v-model="croId"
                          :options="croOption"
                          label="nameTh"
                          track-by="croId"
                          :show-labels="false"
                          placeholder="ค้นหา"
                          @search-change="getCro()"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        อีเวนท์ :

                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="eventId"
                          :options="eventOption"
                          label="nameTh"
                          track-by="eventId"
                          :show-labels="false"
                          placeholder="ค้นหา"
                          @search-change="getEvents()"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        ผู้แนะนำ :
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="recommenderId"
                          label="nameTh"
                          type="search"
                          :options="rowsCustomers"
                          :show-labels="false"
                          open-direction="bottom"
                          @search-change="getRecommenderId"
                          @remove="getRecommenderId"
                          :custom-label="customLabelCustomer"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.recommenderId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.recommenderId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.recommenderId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ค่านำพา :
                        <input
                          :disabled="statusBooking != 'DFT'"
                          type="number"
                          style="text-align:right"
                          v-model="referralFee"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.referralFee.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.referralFee.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.referralFee.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งทะเบียน<br />
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="licensePlateDelivery"
                          :options="postType"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitform && $v.licensePlateDelivery.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.licensePlateDelivery.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.licensePlateDelivery.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code> การจัดส่งรถ <br />
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="productDelivery"
                          :options="postType"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitform && $v.productDelivery.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.productDelivery.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.productDelivery.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code> ประเภทภาษี <br />
                        <multiselect
                          :disabled="statusBooking != 'DFT'"
                          v-model="taxType"
                          :options="taxTypeOptions"
                          label="nameTh"
                          :class="{
                            'is-invalid': submitform && $v.taxType.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.taxType.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.taxType.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="mb-3 position-relative">
                        สถานที่จัดส่ง :
                        <multiselect
                          v-model="addrId"
                          label="fullAddress"
                          type="search"
                          :options="rowsArr"
                          :show-labels="false"
                          open-direction="bottom"
                          :disabled="nameTh == '' || statusBooking != 'DFT'"
                          @search-change="getCustomerArr"
                          @remove="getCustomerArr"

                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.addrId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.addrId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.addrId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-sm-8">
                      <div class="mb-3 position-relative">
                        หมายเหตุ :
                        <textarea
                          :disabled="statusBooking != 'DFT'"
                          v-model="note"
                          class="form-control"
                          name="textarea"
                          rows="5"
                          :class="{
                            'is-invalid': submitform && $v.note.$error,
                          }"
                        ></textarea>
                        <div
                          v-if="submitform && $v.note.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.note.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-sm-8">
                      <div class="mb-3 position-relative">
                        หมายเหตุ (ที่ปรึกษาการขาย)  :
                        <textarea
                          :disabled="statusBooking != 'DFT'"
                          v-model="saleNote"
                          class="form-control"
                          name="textarea"
                          rows="5"
                          
                        ></textarea>
                        
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <b-button
                          :disabled="statusBooking != 'DFT'"
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <!-- <div class="row">
            <div class="col-6">
              <p class="card-title-desc">รายละเอียดการจอง1</p>
            </div>
            <div class="col-6"></div>
          </div> -->
          <categoryUploadComponent
            :paramsIdNum="parseInt(this.$route.params.bookingId)"
            :urlShow="urlShow"
            :urlUpload="urlUpload"
            :urlFullsize="urlFullsize"
            :urlDelete="urlDelete"
            :paramsShow="paramsShow"
            :paramsDel="paramsDel"
            :paramsSending="paramsSending"
            :paramsFullsize="paramsFull"
          ></categoryUploadComponent>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <p class="card-title-desc">รายละเอียดการจอง</p>
            </div>
            <div class="col-6"></div>
          </div>
          <b-tabs
            justified
            nav-class="nav-tabs-custom"
            content-class="p-3 text-muted"
          >
            <b-tab active @click="changeType('ACCY')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">อุปกรณ์เสริม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.bookingAccy.length > 0">
                      <tr
                        v-for="(anAccyItem, index) in bookingAccy"
                        :key="index"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' ||
                                statusBooking != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' ||
                                statusBooking != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' ||
                                statusBooking != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePbookingDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePbookingDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusBooking == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                :disabled="statusBooking != 'DFT'"
                                title="Delete"
                                @click="ConfirmDelAccy(bookingDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.bookingAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารอุปกรณ์เสริม ,ของแถม</label
                    >
                    <multiselect
                      :disabled="statusBooking != 'DFT'"
                      id="accyCode"
                      v-model="accyCode"
                      :options="rowsAccy"
                      label="partId"
                      :custom-label="customLabelAccy"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModal(accyCode)"
                      :close-on-select="true"
                      :internal-search="true"
                      @search-change="getAccy"
                    >
                    </multiselect>

                    <!-- @ok="handleOk" -->
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab @click="changeType('PM')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ของแถม</span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสอะไหล่</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.bookingPm.length > 0">
                      <tr v-for="(anAccyItem, index) in bookingPm" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.partCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.partNameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            disabled
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            disabled
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePbookingDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePbookingDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusBooking == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                :disabled="statusBooking != 'DFT'"
                                title="Delete"
                                @click="ConfirmDelAccy(bookingDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.bookingAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารอุปกรณ์เสริม ,ของแถม</label
                    >
                    <multiselect
                      :disabled="statusBooking != 'DFT'"
                      id="accyCode"
                      v-model="accyCode"
                      :options="rowsAccy"
                      label="partId"
                      :custom-label="customLabelAccy"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModal(accyCode)"
                      :close-on-select="true"
                      :internal-search="true"
                      @search-change="getAccy"
                    >
                    </multiselect>

                    <!-- @ok="handleOk" -->
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab @click="changeType('OTH')">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ค่าใช้จ่ายอื่นๆ </span>
              </template>
              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">รหัสค่าใช้จ่าย</th>
                        <th scope="col">ประเภท</th>
                        <th scope="col">ชื่ออะไหล่</th>
                        <th scope="col">ราคา/หน่วย</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col" class="text-end">รวม</th>
                        <th scope="col">ส่วนลด(%)</th>
                        <th scope="col">ส่วนลดยอด</th>
                        <th scope="col" class="text-end">หลังลด</th>
                        <!-- <th scope="col" class="text-end">vat (%)</th>
                            <th scope="col" class="text-end">vat</th> -->
                        <!-- <th scope="col" class="text-end">สุทธิ</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="this.bookingOth.length > 0">
                      <tr
                        v-for="(anAccyItem, index) in bookingOth"
                        :key="index"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ anAccyItem.extraCode }}</td>
                        <td>{{ anAccyItem.type }}</td>
                        <td>{{ anAccyItem.nameTh }}</td>

                        <td class="number">
                          <input
                            v-model="anAccyItem.pricePerOne"
                            class="form-control text-end"
                            :disabled="
                              anAccyItem.status == 'APV' ||
                                statusBooking != 'DFT'
                            "
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>

                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' ||
                                statusBooking != 'DFT'
                            "
                            v-model="anAccyItem.amount"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>
                        <td class="text-end">{{ anAccyItem.totalPrice }}</td>
                        <td class="Dc">
                          <input
                            :disabled="
                              anAccyItem.status == 'APV' ||
                                statusBooking != 'DFT'
                            "
                            v-model="anAccyItem.dcPer"
                            class="form-control text-end"
                            @keyup.enter="
                              putAccy(
                                anAccyItem.bookingdId,
                                anAccyItem.partId,
                                anAccyItem.pricePerOne,
                                anAccyItem.amount,
                                anAccyItem.totalPrice,
                                anAccyItem.dcPer,
                                anAccyItem.dcPrice,
                                anAccyItem.netPrice,
                                anAccyItem.vatPer,
                                anAccyItem.vatPrice,
                                anAccyItem.grandTotalPrice,
                                anAccyItem.extraId
                              )
                            "
                            @input="calculatePbookingDt(anAccyItem)"
                            @change="calculatePbookingDt(anAccyItem)"
                          />
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePbookingDt(anAccyItem)"
                        >
                          {{ anAccyItem.dcPrice }}
                        </td>
                        <td
                          class="text-end"
                          @change="calculatePbookingDt(anAccyItem)"
                        >
                          {{ anAccyItem.netPrice }}
                        </td>

                        <td>
                          <ul class="list-inline mb-0 action">
                            <li class="list-inline-item">
                              <a
                                v-if="statusBooking == 'DFT'"
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                :disabled="statusBooking != 'DFT'"
                                @click="ConfirmDelAccy(bookingDetails, index)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.bookingAccy.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="accyCode"
                      ><code>*</code> ค้นหารายการค่าใช้จ่ายอื่นๆ
                    </label>
                    <multiselect
                      :disabled="statusBooking != 'DFT'"
                      id="accyCode"
                      v-model="exCode"
                      :options="rowsExtra"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      @input="showModalEx(exCode)"
                      :custom-label="customLabelEx"
                      :close-on-select="true"
                      :internal-search="true"
                      @search-change="getExtra"
                    >
                    </multiselect>

                    <!-- @ok="handleOk" -->
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ผู้ค้ำประกัน</span>
              </template>
              <b-table hover :items="rowsGuarantor" :fields="fieldsGuarantor">
                <template #cell(index)="rowsGuarantor">
                  {{ rowsGuarantor.index + 1 }}
                </template>
                <template #cell(action)="rowsGuarantor">
                  <a
                    href="javascript:void(0);"
                    class="px-2 text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    :disabled="statusBooking != 'DFT'"
                    @click="confirmDelGuarantor(rowsGuarantor.item.guarantorId)"
                  >
                    <i class="uil uil-trash font-size-14"></i>
                  </a>
                </template>
              </b-table>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3 position-relative">
                    เพิ่มผู้ค้ำประกัน :
                    <multiselect
                      v-model="customerG"
                      label="nameTh"
                      type="search"
                      :options="rowsCustomers"
                      :show-labels="false"
                      open-direction="bottom"
                      @search-change="getCustomer"
                      @remove="getCustomer"
                      :custom-label="customLabelCustomer"
                      @input="postGuarantor(customerG)"
                      placeholder=""
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">ค่างวด</span>
              </template>

              <b-table hover :items="rowsInst" :fields="fieldsInst">
                <template #cell(dueDate)="rowsInst">
                  <changeDate :date="rowsInst.item.dueDate"></changeDate>
                </template>
                <template #cell(action)="rowsInst">
                  <a
                    href="javascript:void(0);"
                    class="px-2 text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="conformDelInst(rowsInst.item.iperiodId)"
                  >
                    <i class="uil uil-trash font-size-14"></i>
                  </a>
                </template>
              </b-table>
              <div class="row">
                <button
                  :disabled="statusBooking != 'DFT'"
                  class="btn btn-primary text-center"
                  v-if="rowsInst.length < 1"
                  v-show="saleCondition.scId == 'I'"
                  @click="processInst()"
                >
                  <i class="uil uil-calender"></i>
                  ประมวลผลค่างวด
                </button>
              </div>
            </b-tab>
          </b-tabs>

          <div class="card-body">
            <h4 class="card-title">
              <h4>
                <strong>สรุปยอด</strong>
              </h4>
            </h4>
            <hr />
            <div class="row">
              <b>ยอดรวมค่าสินค้า</b>
              <div class="col-3">
                <div class="mb-3 position-relative">
                  ราคาสินค้า (บาท) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="vehicleTotalPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleTotalPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleTotalPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleTotalPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="vehicleDcPer"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    disabled
                    type="number"
                    style="text-align:right"
                    v-model="vehicleDcPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="vehicleNetPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.vehicleNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.vehicleNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.vehicleNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าอุปกรณ์เสริม</b>
              <div class="col-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าอุปกรณ์เสริม (บาท) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="ttAccy"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.ttAccy.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttAccy.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttAccy.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="partDcPer"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.partDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    disabled
                    type="number"
                    style="text-align:right"
                    v-model="partDcPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.partDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="partNetPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.partNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.partNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.partNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <b>ยอดรวมค่าใช้จ่ายอื่น ๆ </b>
              <div class="col-3">
                <div class="mb-3 position-relative">
                  ยอดรวมค่าใช้จ่ายอื่น ๆ (บาท) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="ttOTH"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.ttOTH.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.ttOTH.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.ttOTH.required">{{ errormessage }}</span>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="mb-3 position-relative">
                  ส่วนลด (%) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    @change="calTotal()"
                    style="text-align:right"
                    v-model="extraDcPer"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.extraDcPer.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPer.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPer.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  ส่วนลด (บาท) :
                  <input
                    disabled
                    type="number"
                    style="text-align:right"
                    v-model="extraDcPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.extraDcPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraDcPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraDcPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="mb-3 position-relative">
                  สุทธิ (บาท) :
                  <input
                    :disabled="statusBooking != 'DFT'"
                    type="number"
                    style="text-align:right"
                    v-model="extraNetPrice"
                    class="form-control"
                    :class="{
                      'is-invalid': submitform && $v.extraNetPrice.$error,
                    }"
                  />
                  <div
                    v-if="submitform && $v.extraNetPrice.$error"
                    class="invalid-tooltip"
                  >
                    <span v-if="!$v.extraNetPrice.required">{{
                      errormessage
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-2">
                <b
                  >รายละเอียด <br />
                  ใบเสนอขายรถ</b
                >
              </div>
              <div class="col-md-2">
                <div style="display: inline-block">
                  <div class="text-end">
                    ยอดรวม : <br />
                    ส่วนลดรวม(%) : <br />
                    ส่วนลดรวม(บาท) : <br />
                    ภาษีรวม(%) : <br />
                    ภาษีรวม(บาท) : <br />
                    สุทธิ : <br />
                    เงินดาวน์ : <br />
                    ยอดจัดไฟแนนซ์ :
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-end">
                {{
                  Number(bookingTotalPrice)
                    .toFixed(2)
                    .toLocaleString()
                }}
                <br />
                {{ Number(bookingDcPer).toFixed(2) }}<br />
                {{
                  Number(bookingDcPrice)
                    .toFixed(2)
                    .toLocaleString()
                }}
                <br />
                {{ parseFloat(Number(vatPer)).toFixed(2) }} <br />
                {{ Number(bookingVatPrice).toLocaleString() }} <br />
                {{
                  Number(parseFloat(bookingGrandTotalPrice)).toLocaleString()
                }}
                <br />
                {{ Number(downPayment).toLocaleString() }} <br />
                {{ Number(totalFinancing).toLocaleString() }}
              </div>
              <div class="col-md-1 text-end">
                บาท<br />
                %<br />
                บาท <br />
                % <br />
                บาท <br />
                บาท <br />
                บาท <br />
                บาท
              </div>
              <!-- มูลค่ารวม -->
              <div
                class="col-5 bg-dark text-white m-0"
                style="border-radius:10px;margin:auto;justify "
              >
                <div class="row align-items-center m-0">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-3">
                        มูลค่ารวม :
                      </div>
                      <div class="col-6 font-size-24">
                        {{
                          Number(
                            parseFloat(this.bookingGrandTotalPrice)
                          ).toLocaleString()
                        }}
                      </div>
                      <div class="col-3">
                        บาท
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />
            <div class="row">
              <hr />
              <div class="text-end">
                <code v-show="statusBooking != 'DFT'"
                  >ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว
                </code>
                <button
                  class="btn btn-success"
                  @click="confirmPutstatusBooking()"
                  :disabled="statusBooking != 'DFT'"
                >
                  ยืนยันใบจอง
                </button>
                <!-- |

                <button
                  class="btn btn-success"
                  @click="putReturnPart()"
                  :disabled="statusBooking == 'APV'"
                >
                  เสร็จสิ้น-กลับสู่หน้าหลัก
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <!-- //? Modal select Part -->
    <b-modal ref="modalAmout" title="ระบุจำนวน" hide-footer size="sm" centered>
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> จำนวน</label>
            <span>({{ this.part.nameTh }})</span>
            <input
              :state="nameState"
              id="amount"
              v-model="amountAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-11" v-show="false">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> %ส่วนลด</label>
            <input
              disabled
              :state="nameState"
              id="amount"
              v-model="dcPerAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
        <div class="col-1" v-show="false">
          &nbsp; <br />
          <br />
          %
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="postAccy(part)" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->

    <!-- //? Modal select Part -->
    <b-modal
      ref="modalAmoutEx"
      title="ระบุจำนวน"
      hide-footer
      size="sm"
      centered
    >
      <div class="row">
        <div class="col">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> จำนวน</label>
            <span>({{ this.ex.nameTh }})</span>
            <input
              :state="nameState"
              id="amount"
              v-model="amountAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-11" v-show="false">
          <div class="mb-3 position-relative">
            <label for="amount"><code>*</code> %ส่วนลด</label>
            <input
              disabled
              :state="nameState"
              id="amount"
              v-model="dcPerAccy"
              type="number"
              style="text-align: center"
              class="form-control"
              value="Otto"
            />
          </div>
        </div>
        <div class="col-1" v-show="false">
          &nbsp; <br />
          <br />
          %
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button @click="postEx(ex)" class="btn btn-primary">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->
    <b-modal
      ref="modalProd"
      id="modalProd"
      title="เลือกสินค้า"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control"
              type="text"
              v-model="searchProdcode"
              placeholder="รหัสสินค้า"
            /><br />
          </div>

          <div class="col-md-4">
            <input
              class="form-control"
              type="text"
              v-model="searchNameProd"
              placeholder="ชื่อสินค้า"
            /><br />
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" @click="getProduct()">
              ค้นหา
            </button>
          </div>
        </div>
        <div class="row">
          <div class="table-responsive mb-0">
            <b-table
              :items="rowProducts"
              :fields="fieldProds"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              select-mode="single"
              @row-selected="onRowSelectedProd"
            >
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalVm"
      id="modalVm"
      title="เลือกรถ"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control"
              type="text"
              v-model="searchVmVin"
              placeholder="เลข vin"
            /><br />
          </div>

          <div class="col-md-4">
            <input
              class="form-control"
              type="text"
              v-model="searchVmName"
              placeholder="ชื่อ"
            /><br />
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" @click="getVehicle()">
              ค้นหา
            </button>
          </div>
        </div>
        <div class="row">
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsVm"
              :fields="fieldVm"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              select-mode="single"
              @row-selected="onRowSelectedVm"
            >
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
